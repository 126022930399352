// Import Core Clean Blog SCSS
@import "../assets/vendor/startbootstrap-clean-blog/scss/styles.scss";

red {
    color: #ff0000;
}

.red {
    color: #ff0000;
}

green {
    color: #2ecc71;
}

.green {
    color: #2ecc71;
}

blue {
    color: #2980b9;
}

.blue {
    color: #2980b9;
}

.subsection {
    padding-left: 0px;
    padding-top: 16px;
}

.subsection img {
    max-width: 100%;
}

.container img {
    max-width: 100%;
}

pre {
    overflow-wrap: normal;
    padding: 16px;
    overflow: auto;
    font-size: 14px;
    line-height: 1.45;
    border-radius: 6px;
    background-color: #2c3e50;
    color: white;
}

code {
    color: #0eac51;
    padding-left: 0px;
    padding-right: 0px;
}

pre code {
    background: transparent !important;
    padding: 0.0 !important;
}

.terminal {
    color: white;
}

div .frame {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 2px solid #111111;
    padding: 0 0 25px 25px;
}

div .frame-no-padding {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 2px solid #111111;
}

iframe {
    width: 100%;
}


pre code.hljs{display:block;overflow-x:auto;padding:1em}code.hljs{padding:3px 5px}.hljs{background:#f3f3f3;color:white}.hljs-comment{color:#6c757d;font-weight:300}.hljs-punctuation,.hljs-tag{color:#f2777a}.hljs-tag .hljs-attr,.hljs-tag .hljs-name{color:#f2777a}.hljs-attribute,.hljs-doctag,.hljs-keyword,.hljs-meta .hljs-keyword,.hljs-name,.hljs-selector-tag{font-weight:700}.hljs-deletion,.hljs-quote,.hljs-selector-class,.hljs-selector-id,.hljs-string,.hljs-template-tag,.hljs-type{color:#59b0f9}.hljs-section,.hljs-number,.hljs-title{color:#f2777a;font-weight:700}.hljs-link,.hljs-operator,.hljs-regexp,.hljs-selector-attr,.hljs-selector-pseudo,.hljs-symbol,.hljs-template-variable,.hljs-variable{color:#cc99cc}.hljs-literal{color:#2ecc71}.hljs-addition,.hljs-built_in,.hljs-bullet,.hljs-code{color:#2ecc71}.hljs-meta{color:#ffcc66}.hljs-meta .hljs-string{color:#f99157}.hljs-emphasis{font-style:italic}.hljs-strong{font-weight:700}

.post-subtitle {
    font-weight: 300;
    font-family: "Hind",sans-serif;
    font-size: 24px;
}

.post-meta {
    font-weight: 300;
}

.subheading {
    font-weight: 300 !important;
    font-family: "Hind",sans-serif !important;
    font-size: 24px !important;
}

.meta {
    font-weight: 300 !important;
}

.nav-link {
    font-size: 14px !important;
    text-shadow: 0 0 2px #fff;
}

.nav-link:hover {
    color: #ffffff55 !important;
}

.navbar {
    background: #77777733!important;
    backdrop-filter: blur(22px)!important;
    -webkit-backdrop-filter: blur(22px)!important;
    border-bottom-color: #ffffff00!important;
}

.page-heading {
    text-shadow: 0 0 3px #fff;
}

.post-heading {
    text-shadow: 0 0 3px #fff;
}

.navbar-brand {
    text-shadow: 0 0 2px #fff;
}

@media screen and (max-width: 992px) {
    .navbar a {
        color: white !important;
    }

    .nav-link {
        padding-top: 15px !important;
        font-size: 14px !important;
        text-shadow: 0 0 2px #fff;
    }

    .navbar-toggler {
        color: white !important;
    }
}

@media only screen and (min-width: 992px) {
    #mainNav.is-fixed .navbar-nav > li.nav-item > a {
        color: white;
    }

    #mainNav.is-fixed .navbar-brand {
        color: white !important;
    }
}

.darkmode--activated p, .darkmode--activated li, .darkmode--activated a {
    color: white;
}

.darkmode--activated a:hover {
    color: #0085A1;
}

.darkmode--activated a:hover h1, .darkmode--activated a:hover h2, .darkmode--activated a:hover h3, .darkmode--activated a:hover h4 {
    color: #0085A1;
}

.darkmode--activated h1, .darkmode--activated h2, .darkmode--activated h3, .darkmode--activated h4 {
    color: white;
}

.darkmode--activated .list-inline-item span, .darkmode--activated blockquote p {
    color: #6c757d;
}

.darkmode-toggle {
    padding-top: 5px;
    z-index: 1337;
}

.darkmode--activated #disqus_thread iframe {
    padding: 20px;
    background: #ffffffaa;
}
