// Bootstrap overrides for this template
.btn {
    font-size: 14px;
    font-weight: 800;
    padding: 15px 25px;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 0;
    font-family: $headings-font-family;
}

.btn-primary {
    background-color: $primary;
    border-color: $primary;

    &:hover,
    &:focus,
    &:active {
        color: $white;
        background-color: darken($primary, 7.5) !important;
        border-color: darken($primary, 7.5) !important;
    }
}

.btn-lg {
    font-size: 16px;
    padding: 25px 35px;
}