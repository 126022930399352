// Styling for the contact page
.floating-label-form-group {
    font-size: 14px;
    position: relative;
    margin-bottom: 0;
    padding-bottom: 0.5em;
    border-bottom: 1px solid $gray-300;

    input,
    textarea {
        font-size: 1.5em;
        position: relative;
        z-index: 1;
        padding: 0;
        resize: none;
        border: none;
        border-radius: 0;
        background: none;
        box-shadow: none !important;

        &::-webkit-input-placeholder {
            color: $gray-600;
        }
    }

    label {
        font-size: 0.85em;
        line-height: 1.764705882em;
        position: relative;
        z-index: 0;
        top: 2em;
        display: block;
        margin: 0;
        -webkit-transition: top 0.3s ease, opacity 0.3s ease;
        -moz-transition: top 0.3s ease, opacity 0.3s ease;
        -ms-transition: top 0.3s ease, opacity 0.3s ease;
        transition: top 0.3s ease, opacity 0.3s ease;
        opacity: 0;
    }

    .help-block {
        margin: 15px 0;
    }
}

.floating-label-form-group-with-value {
    label {
        top: 0;
        opacity: 1;
    }
}

.floating-label-form-group-with-focus {
    label {
        color: $primary;
    }
}

form .form-group:first-child .floating-label-form-group {
    border-top: 1px solid $gray-300;
}